<template>
  <section class="container">
    <GoBack />
    <Breadcrumb class="sobre_section_bc-color">
      <template v-slot:breadcrumbLinks>
        <router-link
          :style="{
            color: color,
          }"
          tag="p"
          :to="{ name: link.href }"
          v-for="(link, idx) in breadcrumbItems"
          :key="idx"
        >
          {{ link.text }}
        </router-link>
      </template>
    </Breadcrumb>
    <v-row class="d-flex justify-center align-center mb-5">
      <div class="d-flex flex-column justify-center align-center mt-3 mb-3">
        <v-img width="40%" src="../../assets/assessoria_juridica.png" alt="" />
      </div>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5 flex-md-row-reverse">
      <v-col
        cols="11"
        md="6"
        class="d-flex justify-center align-center mx-auto"
      >
        <div class="section__container_card">
          <Card
            title_alignment="center"
            borderColor="#C92F05"
            titleColor="#C92F05"
          >
            <template v-slot:conteudo>
              <p>
                O Termo de Transação e Ajustamento de Conduta (TTAC) de 02 de
                outubro de 2018 inaugurou no território de Mariana a Fase de
                Negociação Extrajudicial (FNE). Com promessas de celeridade na
                reparação dos danos às famílias atingidas pelo rompimento, o
                procedimento foi criado com o intuito de ser a via prioritária e
                de acesso rápido à reparação integral, devendo guiar-se pelas
                declarações coletadas no processo de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_cadastro' }"
                  >Cadastramento</router-link
                >
                realizado pela Cáritas Brasileira Regional Minas Gerais e sendo
                garantida a inversão do ônus da prova<sup>1</sup> às pessoas
                atingidas.
              </p>
              <p>
                Foi acordado que a FNE aconteceria às expensas da Fundação
                Renova, a qual, conforme Cláusula 209 do TTAC de 2016, embora
                criada pela Samarco e seus acionistas, deveria ter autonomia
                para gerir e executar os programas de reparação socioambientais
                e socioeconômicos. É importante evidenciar, então, que todo o
                procedimento foi desenvolvido e estruturado sem qualquer
                participação ativa das pessoas atingidas.
              </p>
              <p>
                Ainda no TTAC de 2018 ficou estabelecido também que as pessoas
                atingidas teriam direito a assistência jurídica gratuita por
                meio da assessoria técnica desenvolvida pela Cáritas MG. Desse
                modo, a partir de fevereiro de 2019, deu-se início à atuação da
                equipe de Assessoria Jurídica (AJ), constituída inicialmente
                pelos assessores técnicos com formação em direito que compunham
                a etapa 5 do processo de Cadastramento. Com a renovação do
                projeto, em julho de 2021, a equipe passa a contar com 39
                assessores jurídicos e 4 assessores técnicos.
              </p>
              <p>
                A AJ realiza alguns tipos de atendimento, compreendendo a
                importância de sua atuação não apenas da perspectiva do
                assessoramento jurídico de fato, no âmbito da FNE, mas também da
                atuação na perspectiva informacional. Assim, de modo geral, os
                atendimentos podem ser: reuniões de entrega do dossiê, resultado
                do processo de Cadastramento; atendimentos para sanar dúvidas
                jurídicas em geral; e assessoramento jurídico na FNE.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="10" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/novos_assessores_paracatu_baixo.png"
            alt=""
          />
          <p style="font-size: 0.5em">
            Formação de novos assessores na comunidade de Paracatu de Baixo.
            Foto: Leonardo Gomes da Silva/Cáritas, 2021.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-3">
      <v-col
        cols="11"
        md="6"
        class="d-flex justify-center align-center mx-auto mb-3"
      >
        <div class="section__container_card">
          <Card borderColor="#C92F05" titleColor="#C92F05">
            <template v-slot:conteudo>
              <p>
                Em todas as frentes de atuação da AJ são produzidos diversos
                documentos, externos e internos, a depender da natureza de cada
                atividade e da necessidade de cada demanda. De forma bastante
                ampla, é possível compreender que os documentos se dividem em
                dois grandes grupos: relatórios internos e instrumentos de
                denúncia. Um exemplo deste último, é o
                <a
                  target="_blank"
                  style="text-decoration: none"
                  href="https://www.google.com/url?q=http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf&sa=D&source=docs&ust=1653146093495057&usg=AOvVaw0U-VdAtCGHL5Bp8DqQcJmC"
                  class="pink--text"
                  >Parecer Técnico Jurídico</a
                >.
              </p>
              <p>
                O trabalho da AJ objetiva, portanto, garantir às pessoas
                atingidas o acesso às informações sobre o processo
                indenizatório, prestando orientações técnicas antes, durante e
                depois da FNE, de forma qualificada, em linguagem adequada,
                oportunizando às famílias o acesso às informações de que
                necessitam.
              </p>
              <p>
                Apesar da garantia de assistência jurídica gratuita ser uma
                grande vitória da luta dos atingidos e atingidas de Mariana, os
                desafios continuam sendo muitos diante dos direitos
                sistematicamente negados ou violados pela Fundação Renova e por
                suas mantenedoras. É certo, no entanto, que a Assessoria
                Jurídica da Cáritas MG continua atuando em prol da garantia e da
                promoção dos direitos das famílias, promovendo a ampla
                participação da população atingida, buscando sempre cumprir seu
                papel na luta pela
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                  >reparação integral</router-link
                >.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="10" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/reuniao_equipe.png"
            alt=""
          />
          <p style="font-size: 0.5em">Reunião da equipe, 2021.</p>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="10">
        <v-card class="text__card pa-3 mb-3">
          <p class="term__text">
            <sup>1</sup> No meio jurídico, a regra geral é a de que o ônus da
            prova, ou seja, quem tem o dever de provar que determinado fato
            aconteceu, é o autor da ação, quem está “acusando”. A inversão do
            ônus da prova é uma situação excepcional, na qual as provas devem
            ser apresentadas pelo réu da ação, que é quem está sendo “acusado”.
            É aplicada nos casos em que há reconhecida vulnerabilidade do autor,
            frente à superior capacidade técnica e econômica do réu, na
            tentativa de equilibrar a desigualdade existente entre as partes.
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 mt-5">
      <div class="d-flex flex-column justify-center align-center">
        <v-btn
          :to="{ name: 'sobre_cadastro' }"
          small
          color="#E06919"
          class="white--text pa-5"
        >
          O Cadastro
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: { Card, Breadcrumb, GoBack },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "<       Quem somos       /",
          href: "cards",
        },
        {
          text: "      Assessoria Técnica      /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "      Assessoria Jurídica",
          href: "sobre_assessoria_juridica",
        },
      ],
    };
  },
};
</script>

<style scoped>
.term__text {
  font-size: 0.5em;
  margin-bottom: 0;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.assessoria_card {
  padding: 2em;
}

.assessoria_card p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}
</style>
